import styles from "./staffOneEditFormEmailButtons.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import { ButtonGroupForEditType } from "stores/StaffModule/types/ButtonGroupForEditType";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneEditFormEmailProps = {
  isEmail: boolean;
  isEditingEmail: boolean;
  setIsEditingEmail: (arg: boolean) => void;
  isAddEmail: boolean;
  setIsAddEmail: (arg: boolean) => void;
  isConfirm?: boolean;
};

const StaffOneEditFormEmailButtons = ({
  isEmail,
  isEditingEmail,
  setIsEditingEmail,
  isAddEmail,
  setIsAddEmail,
  isConfirm
}: StaffOneEditFormEmailProps) => {
  const { staffOneEditStore } = useStores();

  const {
    values,
    initialValues,
    dirty,
    isValid,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();

  const editEmail = (action: string) => {
    setIsEditingEmail(false);
    setIsAddEmail(false);
    const contacts: { email?: string | null; email_confirm?: number | null } =
      {};

    switch (action) {
      case "submit":
        if (isEmail) contacts["email"] = values["email"];
        if (isConfirm) contacts["email_confirm"] = values["email_confirm"] || 0;
        staffOneEditStore.updateField(
          staffOneEditStore.selectedOneForEdit["id"],
          contacts,
          "email"
        );
        break;
      case "edit":
        setIsEditingEmail(true);
        break;
      case "delete":
        if (isEmail) contacts["email"] = "";
        if (isConfirm) contacts["email_confirm"] = 0;
        staffOneEditStore.updateField(
          staffOneEditStore.selectedOneForEdit["id"],
          contacts,
          "email"
        );
        break;
      case "cancel":
        if (isAddEmail) {
          isEmail && setFieldValue("email", null);
          isConfirm && setFieldValue("email_confirm", null);
          setIsAddEmail(false);
        } else {
          isEmail && setFieldValue("email", initialValues["email"]);
          isConfirm &&
            setFieldValue("email_confirm", initialValues["email_confirm"]);
          setFieldTouched("email", false);
          setFieldTouched("email_confirm", false);
        }
        break;
      default:
        break;
    }
  };
  const editButtons: ButtonGroupForEditType = isEditingEmail
    ? [
        {
          action: "submit",
          type: "button",
          icon: "bigcheck",
          color: !isValid || !dirty ? "bw-gray3" : "blue-lazure",
          disabled: !isValid || !dirty
        },
        {
          action: "cancel",
          type: "button",
          icon: "iconclose",
          color: "blue-lazure",
          disabled: false
        },
        {
          action: "delete",
          type: "button",
          icon: "iconbasket",
          color: "bw-gray5",
          disabled: true
        }
      ]
    : [
        {
          action: "edit",
          type: "button",
          icon: "iconedit",
          color: "bw-gray5",
          disabled: false
        },
        {
          action: "delete",
          type: "button",
          icon: "iconbasket",
          color: "bw-gray5",
          disabled: false
        }
      ];
  const addButtons: ButtonGroupForEditType = [
    {
      action: "submit",
      type: "button",
      icon: "bigcheck",
      color: !isValid || !dirty ? "bw-gray3" : "blue-lazure",
      disabled: !isValid || !dirty
    },
    {
      action: "cancel",
      type: "button",
      icon: "iconclose",
      color: "blue-lazure",
      disabled: false
    }
  ];

  return (
    <li className={classNames(styles.field, {}, [styles.buttons])}>
      <ButtonsGroupForEdit
        btns={isAddEmail ? addButtons : editButtons}
        onClick={editEmail}
      />
    </li>
  );
};

export default observer(StaffOneEditFormEmailButtons);
