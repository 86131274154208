import styles from "./staffOneEditFormEmail.module.scss";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { ViewField } from "shared/ui/ViewField";
import StaffOneEditFormEmailButtons from "./StaffOneEditFormEmailButtons";

type StaffOneEditFormEmailProps = {
  disabled: boolean;
};

const StaffOneEditFormEmail = ({ disabled }: StaffOneEditFormEmailProps) => {
  const { staffOneEditStore } = useStores();

  const [isAddEmail, setIsAddEmail] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [isEmail, setIsEmail] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const {
    values,
    initialValues,
    dirty,
    setFieldTouched,
    handleChange,
    handleBlur
  } = useFormikContext();

  useEffect(() => {
    if (initialValues["email"] !== undefined) setIsEmail(true);
    if (initialValues["email_confirm"] !== undefined) setIsConfirm(true);
  }, []);

  useEffect(() => {
    // при handleReset() обнуляются значения в полях, но не меняется состояние формы добавления email'a
    // поэтому если нет изменений в форме и состояние формы добавления email'a "открыта"
    if (!dirty && isAddEmail) {
      // изменяем состояние формы на "закрыто" и кнопка "Добавить +" будет отображаться
      setIsAddEmail(false);
      setIsEditingEmail(false);
    }
  }, [dirty]);

  return !staffOneEditStore.isLoadingEmail ? (
    <div className={`${styles.form} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.subtitle}>Электронная почта</div>
      <div className={styles.containerForm}>
        {values["email"] || isEditingEmail || isAddEmail ? (
          <ul
            className={
              isEditingEmail
                ? `${styles.confirmRowEmail} ${styles.confirmRowEmail_selected}`
                : styles.confirmRowEmail
            }
          >
            {isAddEmail ? null : isConfirm ? (
              <li className={styles.confirmRowEmail__confirm}>
                <label htmlFor="email_confirm">
                  {staffOneEditStore.staffTableCols["email_confirm"]["title"]}
                </label>
                <Checkbox
                  name="custom.email_confirm"
                  id="email_confirm"
                  value={values["email_confirm"]}
                  disabled
                />
              </li>
            ) : null}
            {isEmail ? (
              <>
                <li className={styles.confirmRowEmail__order}>
                  <ViewField
                    title="№"
                    value="1"
                    className={{ container: styles.confirmRowEmailBorder }}
                  />
                </li>
                <li className={styles.confirmRowEmail__email}>
                  <Input
                    name="email"
                    label={staffOneEditStore.staffTableCols["email"]["title"]}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("email");
                    }}
                    onBlur={handleBlur}
                    disabled={!isEditingEmail}
                  />
                </li>
                <StaffOneEditFormEmailButtons
                  isEmail={isEmail}
                  isEditingEmail={isEditingEmail}
                  setIsEditingEmail={setIsEditingEmail}
                  isAddEmail={isAddEmail}
                  setIsAddEmail={setIsAddEmail}
                  isConfirm={isConfirm}
                />
              </>
            ) : null}
          </ul>
        ) : (
          <button
            id={`StaffOneEditEmail_addEmailFormButton`}
            type="button"
            className={styles.buttonAddNumber}
            onClick={() => {
              setIsAddEmail(true);
              setIsEditingEmail(true);
            }}
          >
            <p className={styles.buttonAddNumber__text}>Добавить</p>
            <img src={addIcon} className={styles.buttonAddNumber__icon} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <p className={styles.loading}>Пожалуйста, подождите...</p>
  );
};

export default observer(StaffOneEditFormEmail);
