import styles from "./modal.module.scss";
import { classNames } from "shared/utils/helpers/classNames";
import { ReactComponent as IconCloseWithoutFill } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import iconOK from "shared/assets/images/mainIcons/iconOK.svg";
import iconError from "shared/assets/images/mainIcons/iconAlert.svg";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

export type ModalType =
  | "success"
  | "clarification"
  | "clarificationForFormWindows";

export type ModalProps = {
  type: ModalType;
  title: string;
  show: boolean;
  subtitle?: string;
  onHide?: () => void;

  successIcon?: boolean;
  successBtn?: boolean;
  errorIcon?: boolean;

  btnWithCrossImg?: boolean;
  btnWithCrossTitle?: string;
  btnWithCrossOnClick?: () => void;

  greyBtnTitle?: string;
  greyBtnOnClick?: () => void;

  blueBtnTitle?: string;
  blueBtnOnClick?: () => void;

  direction?: "column" | "row";
  className?: string;
};

const Modal = ({
  type,
  title,
  show,
  subtitle,
  onHide,
  successIcon,
  successBtn,
  errorIcon,
  btnWithCrossImg,
  btnWithCrossTitle,
  btnWithCrossOnClick,
  greyBtnTitle,
  greyBtnOnClick,
  blueBtnTitle,
  blueBtnOnClick,
  direction = "column",
  className
}: ModalProps) => {
  return (
    <BootstrapModal
      show={show}
      onHide={onHide}
      dialogClassName={
        type === "clarificationForFormWindows"
          ? "modal-lg"
          : type === "success"
          ? ""
          : null
      }
      centered
    >
      <BootstrapModal.Body>
        <div
          className={classNames(styles.modalBody, {
            [styles.modalBodyWithoutIcon]: !successIcon || !errorIcon
          })}
        >
          <div
            className={classNames("", {
              [styles.modalBodyRow]: direction === "row"
            })}
          >
            {successIcon ? <img src={iconOK} alt="success" /> : ""}
            <div
              className={classNames("", {
                [styles.errorTitle]: errorIcon,
                [styles.title]: !errorIcon
              })}
            >
              {title}
            </div>
            <div
              className={classNames("", { [styles.modalBodyRow]: errorIcon })}
            >
              {errorIcon ? <img src={iconError} alt="error" /> : ""}
              {subtitle ? (
                <div className={styles.subTitle}>{subtitle}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {type !== "success" && (
          <div
            className={classNames(styles.btnGroup, { [className]: className })}
          >
            {btnWithCrossTitle && (
              <Button
                id="Modal_closeBtn"
                type="button"
                onClick={btnWithCrossOnClick}
                theme={
                  successIcon || successBtn
                    ? ButtonTheme.SECONDARY
                    : ButtonTheme.CANCELLED
                }
                size={ButtonSize.L}
                className={styles.closeBtn}
              >
                {btnWithCrossImg && (!successIcon || !successBtn) ? (
                  <IconCloseWithoutFill className={styles.iconClose} />
                ) : (
                  ""
                )}
                {btnWithCrossTitle}
              </Button>
            )}

            {blueBtnTitle && (
              <Button
                id="Modal_blueBtn"
                type="button"
                onClick={blueBtnOnClick}
                size={ButtonSize.L}
              >
                {blueBtnTitle}
              </Button>
            )}

            {greyBtnTitle && (
              <Button
                id="Modal_greyBtn"
                type="button"
                onClick={greyBtnOnClick}
                theme={ButtonTheme.SECONDARY}
                size={ButtonSize.L}
              >
                {greyBtnTitle}
              </Button>
            )}
          </div>
        )}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
